import { createAction } from "@reduxjs/toolkit";
import { IAppUser } from "./types";

export const appUserDataRequest = createAction<void>("APP_USER_DATA/REQUEST");

export const appUserDataSuccess = createAction<IAppUser>(
  "APP_USER_DATA/SUCCESS"
);

export const appUserDataFail = createAction<Error>("APP_USER_DATA/FAIL");

export const uploadCsvFileRequest = createAction<any>('UPLOAD_CSV_FILE_REQUEST');
export const uploadCsvFileSuccess = createAction('UPLOAD_CSV_FILE_SUCCESS');
export const uploadCsvFileFail = createAction('UPLOAD_CSV_FILE_FAIL');
