import {getAxiosAppUser, postAxiosUploadCsvFile} from "@API/AppUser/endpoints";
import {IGetAxiosAppUser} from "@API/AppUser/types";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    appUserDataFail,
    appUserDataRequest,
    appUserDataSuccess,
    uploadCsvFileFail,
    uploadCsvFileRequest,
    uploadCsvFileSuccess,
} from "./actions";
import {notification} from "antd";
import i18n from "@i18n";

function* onAppUserDataRequest() {
    try {
        const appUser: IGetAxiosAppUser = yield call(getAxiosAppUser);
        if (appUser.error) throw appUser.error;
        yield put(appUserDataSuccess(appUser.data));

    } catch (e) {
        console.error(e);
        yield put(appUserDataFail(e as Error));
    }
}

function* onUploadCsvFileRequest(action: { payload: { orgId: string; csvContent: string }; }) {
    try {
        const {orgId, csvContent} = action.payload;
        yield call(postAxiosUploadCsvFile, orgId, csvContent);
        yield put(uploadCsvFileSuccess());

        notification.success({
            message: i18n.t("organization.bulkUsersUpload.successMessage"),
        });

    } catch (e) {
        console.error(e);
        yield put(uploadCsvFileFail());


        notification.error({
            message: i18n.t("organization.bulkUsersUpload.errorMessage"),
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(appUserDataRequest, onAppUserDataRequest);
    yield takeLatest(uploadCsvFileRequest, onUploadCsvFileRequest);
}
