import React, { useEffect, useState } from 'react';
import { Image } from "antd";
import {
    FileUploadContainer,
    FileUploadInfo,
    HiddenInput,
    ImagePlaceholderContainer,
    InputLabel,
    RemoveButton,
    UploadBox, UploadBoxNoPreview,
    UploadButton,
    UploadedImage
} from "@Components/FileUpload/styles";

interface FileUploadProps {
    id: string;
    name: string;
    accept: string;
    formData: FormData;
    updateParentFormData: (formData: FormData) => void;
    labelText: string;
    initialFileSrc?: string;
    onFileStateChange?: (b: boolean) => void;
    preview?: boolean;
    fileInfo?: string;
    className?: string;
    buttonText?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
                                                   id,
                                                   name,
                                                   accept,
                                                   formData,
                                                   updateParentFormData,
                                                   labelText,
                                                   initialFileSrc,
                                                   onFileStateChange,
                                                   preview = false,
                                                   fileInfo = "Min 200x200px, max 5MB",
                                                   className,
                                                   buttonText
                                               }) => {
    const [fileSrc, setFileSrc] = useState(initialFileSrc);
    const [fileName, setFileName] = useState<string | null>(null);

    useEffect(() => {
        setFileSrc(initialFileSrc);
    }, [initialFileSrc]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (preview) {
                setFileSrc(URL.createObjectURL(file));
            } else {
                setFileName(file.name);
            }
            formData.delete(name);
            formData.append(name, file);
            updateParentFormData(formData);
            onFileStateChange?.(true);
        }
    };

    const removeFile = () => {
        setFileSrc('');
        setFileName(null);
        formData.delete(name);
        updateParentFormData(formData);
        onFileStateChange?.(false);
    };

    return (
        <FileUploadContainer className={className}>
            {preview ? (
                <UploadBox>
                    {fileSrc ? (
                        <div>
                            <UploadedImage src={fileSrc} alt="Uploaded preview" />
                            <RemoveButton onClick={removeFile}>x</RemoveButton>
                        </div>
                    ) : (
                        <ImagePlaceholderContainer>
                            <Image
                                width={20}
                                src={`${process.env.PUBLIC_URL}/Assets/imagePlaceholder.svg`}
                            />
                        </ImagePlaceholderContainer>
                    )}
                </UploadBox>
            ) : (
                <UploadBoxNoPreview>
                    {fileName ? (
                        <div>
                            <span>{fileName}</span>
                            <RemoveButton onClick={removeFile}>x</RemoveButton>
                        </div>
                    ): (
                        <div>
                            <span className='upload-file-placeholder'>UPLOAD FILE ...</span>
                        </div>
                    )}
                </UploadBoxNoPreview>
            )}

            <FileUploadInfo>
                {fileInfo}
            </FileUploadInfo>

            <InputLabel htmlFor={id}>
                <HiddenInput
                    id={id}
                    type="file"
                    name={name}
                    accept={accept}
                    onChange={handleFileChange}
                />
                <UploadButton>
                    {buttonText || labelText}
                </UploadButton>
            </InputLabel>
        </FileUploadContainer>
    );
};

export default FileUpload;
