import {SearchOutlined} from '@ant-design/icons';
import Input from 'antd/lib/input';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {InputContainer, SearchBlock} from './styles';

interface ISearchInput {
    className?: string,
    onChangeHandler: (value: string) => void,
}

const SearchInput: FC<ISearchInput> = ({onChangeHandler, className}) => {
    const {t} = useTranslation();
    return (
        <InputContainer
            className={className}>
            <Input
                placeholder={t('forms.search')}
                onChange={e => onChangeHandler(e.target.value)}
            />
            <SearchBlock>
                <SearchOutlined/>
            </SearchBlock>
        </InputContainer>
    );
};

export default SearchInput;