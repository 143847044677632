import styled from 'styled-components';

export const FileUploadContainer = styled.div`
    margin-bottom: 0.5rem;
    width: 120px;
`;

export const UploadBox = styled.div`
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid #d1d5db;
    padding: 0.75rem;
    min-height: 120px;
`;

export const UploadBoxNoPreview = styled.div`
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid #d1d5db;
    padding: 0.75rem;
    min-height: 40px;
    
    .upload-file-placeholder {
        color: #9ca3af;
        font-size: 12px;
        font-weight: bold;
    }
`;

export const UploadedImage = styled.img`
    height: 10rem;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
`;

export const RemoveButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 9999px;
    border: none;
    background-color: #ffffff;
    color: #f87171;
    font-size: 0.75rem;
    line-height: 1.25rem;
    &:hover {
        background-color: #fee2e2;
    }
`;

export const InputLabel = styled.label`
    display: block; // Makes the label behave like a container
    cursor: pointer;
`;

export const UploadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.175rem;
    background-color: #9ca3af;
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    &:hover {
        background-color: #d1d5db;
    }
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const FileUploadInfo = styled.div`
    color: gray;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    
    span {
        font-size: 10px;
    }
`

export const ImagePlaceholderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    width: 100%;
    `;