import {SetStateAction, useEffect, useState} from "react";
import MainComponent from "@src/MainComponent";
import {Button, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    CorporateContainer,
    CorporateContainerLeftSide, CorporateContainerLeftSideActions, CorporateContainerLeftSideUpload,
    CorporateContainerRightSide, CorporateContainerRightSideActions, CorporateContainerRightSideUpload
} from "@Pages/CorporatePage/styles";
import FileUpload from "@Components/FileUpload";
import {useAppDispatch, useAppSelector} from "@hooks";
import {
    selectCurrentOrganization,
    selectIsAdminOfCurrentOrganization,
    selectOrganizationsGeneralData
} from "@Store/Organizations/selectors";
import {uploadImageOrganizationRequest} from "@Store/Organizations/actions";
import {selectAppUserData} from "@Store/AppUser/selectors";
import {RootState} from "@Store";
import {useHistory} from "react-router-dom";

const {Text} = Typography;

function CorporatePage() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [logoFormData, setLogoFormData] = useState(() => new FormData());
    const [profileFormData, setProfileFormData] = useState(() => new FormData());
    const [isLogoSaveDisabled, setIsLogoSaveDisabled] = useState(true);
    const [isProfileSaveDisabled, setIsProfileSaveDisabled] = useState(true);


    const {
        currentOrganization,
        userData,
        isAdminOfCurrentOrganization
    } = useAppSelector((state: RootState) => ({
        currentOrganization: selectCurrentOrganization(state),
        userData: selectAppUserData(state),
        isAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    }));

    const updateLogoFormData = (newFormData: SetStateAction<FormData>) => {
        setLogoFormData(newFormData);
        setIsLogoSaveDisabled(false);
    };

    const updateProfileFormData = (newFormData: SetStateAction<FormData>) => {
        setProfileFormData(newFormData);
        setIsProfileSaveDisabled(false);
    };

    const handleUploadLogo = (e: { preventDefault: () => void; }) => {
        dispatch(uploadImageOrganizationRequest({
            org_id: currentOrganization?.orgId,
            file_type: "LOGO",
            file: logoFormData
        }));
    }

    const handleUploadProfile = () => {
        dispatch(uploadImageOrganizationRequest({
            org_id: currentOrganization?.orgId,
            file_type: "PROFILE",
            file: profileFormData
        }));
    }

    return (
        <MainComponent>
            <div style={{width: '100%'}}>
                <h2>Corporate</h2>
                <CorporateContainer>
                    <CorporateContainerLeftSide>
                        <CorporateContainerLeftSideUpload>
                            <h2>Firmenlogo</h2>
                            <FileUpload
                                formData={logoFormData}
                                accept={"image/*"}
                                id={"logo-file"}
                                name={"file"}
                                preview={true}
                                labelText={"Upload"}
                                updateParentFormData={updateLogoFormData}
                                initialFileSrc={`${currentOrganization.orgLogoUrl}`}
                                onFileStateChange={(state) => {
                                    setIsLogoSaveDisabled(!state)
                                }}
                                fileInfo={'Min 200x200px, max 5MB'}
                            />

                        </CorporateContainerLeftSideUpload>
                        <CorporateContainerLeftSideActions>
                            <Button type="primary" disabled={isLogoSaveDisabled} onClick={handleUploadLogo}>Save
                                Logo</Button>
                        </CorporateContainerLeftSideActions>
                    </CorporateContainerLeftSide>
                    <CorporateContainerRightSide>
                        <CorporateContainerRightSideUpload>
                            <h2>Firmen Info</h2>
                            <FileUpload
                                formData={profileFormData}
                                accept={"image/*"}
                                id={"profile-file"}
                                name={"file"}
                                labelText={"Upload"}
                                updateParentFormData={updateProfileFormData}
                                initialFileSrc={currentOrganization.orgInfoUrl || ''}
                                preview={true}
                                onFileStateChange={(state) => {
                                    setIsProfileSaveDisabled(!state);
                                }}
                                fileInfo={'Min 200x200px, max 5MB'}
                            />
                        </CorporateContainerRightSideUpload>
                        <CorporateContainerRightSideActions>
                            <Button type="primary" disabled={isProfileSaveDisabled} onClick={handleUploadProfile}>Save
                                Profile</Button>
                        </CorporateContainerRightSideActions>
                    </CorporateContainerRightSide>
                </CorporateContainer>
            </div>
        </MainComponent>
    );
}

export default CorporatePage;
