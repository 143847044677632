import "./index.scss";
import Table from "@Components/Table";
import { USERS_TABLE_COLUMNS } from "@Pages/UsersPage/usersColumns";
import { IOrganizationUser } from "@Store/Organizations/types";
import { setUserModal, setUserModalType } from "@Store/Users/actions";
import { selectOrganizationUsers } from "@Store/Users/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import { TableColumn } from "react-data-table-component";
import { toggleUserActiveStatus } from "@Store/Organizations/actions";
import { useState } from "react";
import { Button, Modal } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import ModalBulkUploadUsers from "./ModalBulkUploadUsers"; // Import the modal component

type UserTabProps = {
    dispatch: ReturnType<typeof useAppDispatch>;
};

function UsersTabContent(props: UserTabProps) {
    const { dispatch } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] = useState(false); // State for Bulk Upload Modal
    const [currentUser, setCurrentUser] = useState<IOrganizationUser | null>(null);
    const userList: IOrganizationUser[] = useAppSelector(selectOrganizationUsers);

    const onEditUser = (row: IOrganizationUser): void => {
        dispatch(setUserModal(row));
        dispatch(setUserModalType("update"));
    };

    const showConfirmModal = (user: IOrganizationUser) => {
        setCurrentUser(user);
        setIsModalVisible(true);
    };

    const handleConfirmToggleActive = (isActive: boolean) => {
        if (currentUser) {
            dispatch(
                toggleUserActiveStatus({
                    org_id: currentUser.orgId,
                    user_id: currentUser.userId,
                    isActive,
                })
            );
        }
        setIsModalVisible(false);
        setCurrentUser(null);
    };

    const handleCancelToggleActive = () => {
        setIsModalVisible(false);
        setCurrentUser(null);
    };

    const showBulkUploadModal = () => {
        setIsBulkUploadModalVisible(true);
    };

    const hideBulkUploadModal = () => {
        setIsBulkUploadModalVisible(false);
    };

    const userTableColumn: TableColumn<IOrganizationUser>[] = USERS_TABLE_COLUMNS(onEditUser, showConfirmModal);

    return (
        <div className="users-tab-content">
            <div className="users-bulk-upload">
                <Button type="primary" icon={<CloudUploadOutlined style={{ color: 'black' }} />} onClick={showBulkUploadModal}>
                    Bulk Upload Users
                </Button>
            </div>

            <Table columns={userTableColumn} data={userList} paginationPerPage={10} />

            <Modal
                title={`Are you sure you want to ${currentUser?.status === 'ACTIVE' || currentUser?.status === 'INVITED' ? 'deactivate' : 'activate'} this user?`}
                visible={isModalVisible}
                onOk={() => handleConfirmToggleActive(currentUser?.status === 'ACTIVE' || currentUser?.status === 'INVITED')}
                onCancel={handleCancelToggleActive}
                okText="Yes"
                cancelText="No"
            >
                <p>{`User: ${currentUser?.givenName} ${currentUser?.surName}`}</p>
            </Modal>

            <ModalBulkUploadUsers visible={isBulkUploadModalVisible} onClose={hideBulkUploadModal} />
        </div>
    );
}

export default UsersTabContent;
