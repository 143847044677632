import { Modal, notification } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FileUpload from "@Components/FileUpload";
import { uploadCsvFileRequest } from "@Store/AppUser/actions";
import { useParams } from "react-router-dom";

interface ModalBulkUploadUsersProps {
    visible: boolean;
    onClose: () => void;
}

const ModalBulkUploadUsers: FunctionComponent<ModalBulkUploadUsersProps> = ({ visible, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params: { orgId: string } = useParams();
    const orgId = params.orgId;
    const [formData, setFormData] = useState(() => new FormData());
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    const updateFormData = (newFormData: FormData) => {
        setFormData(newFormData);
    };

    const handleSubmit = () => {
        const file = formData.get('file') as File;
        if (!file) {
            notification.error({
                message: 'No file selected',
                description: 'Please upload a CSV file.',
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            let csvContent = reader.result as string;

            // Replace semicolons with commas
            csvContent = csvContent.replace(/;/g, ',');

            const lines = csvContent.split('\n').map(line => line.trim()).filter(line => line);
            const plainTextContent = lines.join('\n');

            if (orgId) {
                dispatch(uploadCsvFileRequest({ orgId, csvContent: plainTextContent }));
            }
            onClose();
        };
        reader.onerror = () => {
            notification.error({
                message: 'File Reading Error',
                description: 'There was an error reading the file.',
            });
        };

        reader.readAsText(file);
    };


    useEffect(() => {
        if (!visible) {
            setFormData(new FormData());
            setIsFileUploaded(false);
        }
    }, [visible]);

    return (
        <Modal
            title={t("bulkUpload.modal.title")}
            visible={visible}
            onCancel={onClose}
            onOk={handleSubmit}
            okButtonProps={{ disabled: !isFileUploaded }}
        >
            <FileUpload
                className="bulk-upload-file"
                id="bulk-upload-file"
                name="file"
                accept=".csv"
                formData={formData}
                updateParentFormData={updateFormData}
                labelText={t("bulkUpload.modal.uploadButton")}
                onFileStateChange={setIsFileUploaded}
                fileInfo={'Please upload a CSV file.'}
                buttonText={'Upload CSV File'}
            />
        </Modal>
    );
};

export default ModalBulkUploadUsers;
